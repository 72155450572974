<template>
      <b-card-body>
          <b-row>
            <b-col cols=6>
              <b-img :src="require('@/assets/images/ssm.png')" fluid alt="Fluid-grow image" />
            </b-col>
            <b-col cols=6>
                <h4>Accepted document criteria:</h4> 
                <ul>
                  <li>Document file must be in JPEG or PDF format.</li>
                  <li>SSM document must be the version of Corporate Information.</li>
                  <li>Visible with high resolution. Blur document / Image will be not accepted.</li>
                  <li>Recommended file size below 300KB. Compress file if file size exceed more than 300KB.</li>
                  <li>Recommended file uploaded in the colour version.</li>
                </ul>
            </b-col>
          </b-row>
    </b-card-body>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BImg
} from 'bootstrap-vue'

export default {
    components:{
        BImg,
        BCard,
        BCardTitle,
        BCardHeader,
        BCardBody
    }
}
</script>
