<template>
  <div>
    <h1 class="text-center my-3">DRIVER SOP POLICY</h1>
    <p>
      <strong>Formula for pay-out for GoLoger for delivery</strong><br />⦁ On
      Demand Delivery: Calculate based on milage. Subject to vehicle type and
      drivers earn 80% of delivery fare.<br />⦁ Next Day Delivery: Not
      applicable.<br />⦁ Distribution Delivery: Payment calculates based on per
      drop / per box basis with fixed price on every quarter / half yearly /
      yearly basis subject on GOLOG latest fare announcement.
    </p>
    <p>
      <br /><strong
        >Ensuring a respectful, safe environment for all GoLogers and
        Users</strong
      ><br />The way you behave while using GoLog can have a big impact on the
      safety and comfort of Users. Courtesy matters. That&rsquo;s why you are
      expected to exercise good judgment and behave decently towards s when
      riding with Golog &mdash; just as you would in any public place.
    </p>
    <p>
      Here are some reasons why you could lose access to GoLog as a GoLoger:<br />If
      we are made aware of this type of problematic behavior, we may contact you
      so we can investigate them. Depending on the nature of the concern, we may
      put a hold on your account during our investigation. If the issues raised
      are serious or a repeat offense, or you refuse to cooperate, you may lose
      access to GoLog. Any behavior involving violence, sexual misconduct,
      harassment, discrimination, or illegal activity while using GoLog can
      result in the immediate loss of access to your account. Additionally, when
      law enforcement is involved, we will cooperate with their investigation.
    </p>
    <p>
      <strong
        >A GoLoger is our partner in GoLog and is responsible for delivering
        parcels in a timely and effective manner. What do the Users expect from
        a GoLoger:</strong
      ><br />⦁ Receiving parcel immediately after accepting a request. When a
      GoLoger accepts a request from User, the parcel should be collected by the
      designated time or by the time stated in the order details page.<br />⦁
      Report damaged parcel to GoLog. If the GoLoger happens to receive a parcel
      which is damaged upon collection, update in comment session mentioning
      damaged parcel but User proceeds, or choose to cancel the delivery and
      list the reason of cancellation to not affect star rating of GoLoger.<br />⦁
      Maintain good delivery standard. Always be on time for pick up and deliver
      it immediately. Keep a good attitude towards users and fellow Gologers.<br />⦁
      Delivering parcels immediately after receiving it. Any deliveries within
      state should be received and delivered within two (2) hours at maximum,
      any late deliveries of the GoLoger will be penalized (up to full amount of
      the delivery), which the GoLoger will be opt out of our future programs or
      rated lower by User.<br />⦁ Handle item with care. On the midst of
      delivery, GoLoger should ensure the condition of the parcel which may
      require GoLoger to stop and check the parcel time to time. If the items
      are found to be damaged and not declared by the User or GoLoger at the
      beginning, it will be the GoLoger&rsquo;s sole responsibility on the
      damage of items.<br />⦁ Not cancelling a delivery after accepting it. Any
      cancellation done after accepting a job will be penalized up to a full
      amount of the delivery fee. However, any cancellation two (2) hours prior
      the pick-up time will not be penalized. Cancellation of jobs can be done
      by informing admin through WhatsApp (0172881753) and mention the order
      ID.<br />⦁ Damaging User&rsquo;s or GoLoger&rsquo;s property. For example,
      damaging the car of GoLoger when passing parcels, or damaging, open or
      keeping the parcel that should be delivered.<br />⦁ Use of inappropriate
      and abusive language or gestures. For example, asking overly personal
      questions, using verbal threats, and making comments or gestures that is
      aggressive, sexual, discriminatory, or disrespectful.<br />⦁ Unwanted
      contact with the GoLoger or fellow passenger after the delivery is over.
      For example, texting, calling, or visiting someone in person after a
      delivery has been completed.<br />⦁ Breaking the local law while using
      GoLog. For example, deliver open containers of alcohol or drugs into the
      car; break local traffic laws such as speed limits; or using GoLog to
      commit a crime, including drug and human trafficking.
    </p>
    <p>
      <strong>Terms of Use</strong><br />As a GoLoger, you agree to our Terms of
      Use when you sign up for your account. We may take action against you for
      violating these terms, including permanently closing your account. For
      example, the failure to maintain accurate, gossiping by harm company
      image, threat, complete, and up-to-date account information, including
      having an invalid or expired payment method on file; allowing a person who
      does not meet the minimum age requirement to use your account while
      unaccompanied, or if you don&rsquo;t meet that age requirement yourself.
    </p>
    <p>
      <strong>App Usage</strong><br />GOLOG app use by Gologer is mandatory turn
      on while performing every delivery job task by turning on app, update pick
      up status with Proof of Delivery (POD) and delivery status with POD
      immediately. 24-hour time frame available for Gologer submit any miss out
      POD on that particular delivery job date. Payment will only calculate
      based on every complete of job status with POD of picked up and delivered
      POD. Driver fails to conduct and did not upload POD within 24 hours any
      issues will not be entertain.
    </p>
    <p>
      <strong>Fraud or Illegitimate Behaviour</strong><br />Fraudulent or
      illegitimate behavior undermines the trust on which GoLog is built. We may
      deactivate any account(s) associated with this type of activity,
      including: abusing promotions; collusion between rider and GoLoger;
      disputing fares for fraudulent or illegitimate reasons; or duplicate
      accounts.
    </p>
    <p>
      <strong>Cancellation Rate</strong><br />A driver cancellation is when you
      accept a trip request and then cancel the trip. Cancellations create a
      poor rider experience and negatively affect other drivers. We understand
      that there may be times when something comes up and you have to cancel an
      accepted trip. But minimizing cancellations is critical for the
      reliability of the system.
    </p>
    <p>
      How is my cancellation rate calculated? Your cancellation allowed will be
      a maximum of three (3) times with valid reason. Any cancellation without
      reason and no show will be imposed a full penalty amount and might lead to
      a permanent ban from the Golog system. Appeal of ban can be done through
      admin@glogc.com.
    </p>
    <p>&nbsp;</p>
  </div>
</template>
<script>
export default {};
</script>
