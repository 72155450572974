<template>
  <div>
    <h1 class="my-3 text-center">PRIVACY TERMS POLICY</h1>
    <p>
      <strong>GOLOG TERMS</strong><br />GoLog platform is to provide an express
      service to enable an individual or persons (&ldquo;users&rdquo;) who
      seeking delivery to specific destinations and real time fulfilment of an
      errand at a location to match with persons with capacity and ability to
      fulfil these tasks ("GoLoger").
    </p>
    <p>
      The services as mentioned above shall collectively be defined as the
      "services". These terms shall act on your use of and participation as user
      or GoLoger in the GoLog platform.
    </p>
    <p>
      FOR THE AVOIDANCE OF DOUBT, WE ARE A TECHNOLOGY COMPANY, NOT A
      TRANSPORTATION OR COURIER COMPANY AND WE DO NOT PROVIDE TRANSPORTATION OR
      COURIER SERVICES.
    </p>
    <p>
      We do not employ the Service Providers and we are not responsible for any
      acts and/or omissions of the Service Providers. The Application is simply
      a means to ease the search of the Services. It is up to the Service
      Providers to offer the Services to you and it is up to you whether to
      accept their offers of Services.
    </p>
    <p>
      <strong>GENERAL CONDITIONS</strong><br />1.&nbsp;&nbsp;&nbsp;&nbsp;GoLog
      terms and conditions as the following below, a society marketplace concept
      application and platform (&ldquo;platform&rdquo;) owned and operated by
      GoBuilders Netsoft Sdn. Bhd. (Company No.1232479-T), incorporated in
      Malaysia. By your sign up and/or usage of GoLog services, website and
      products, you are legally and automatically bound to these terms and
      conditions without conditions.<br />2.&nbsp;&nbsp;&nbsp;&nbsp;By virtue of
      your sign up and / or usage, you accept all terms and conditions of the
      GoLog platform. This means that you affirm your understanding of the terms
      and conditions of GoLog and are within full capacity to enter into
      transaction with GoLog and GoBuilders Netsoft Sdn Bhd for its services
      and/or products.<br />3.&nbsp;&nbsp;&nbsp;&nbsp;GoLog does not provide
      carrier, transportation or delivery services. It is up to the GoLog to
      decide whether or not to offer delivery services to a User on the GoLog
      platform and it is up to the GoLoger to decide whether or not to accept a
      delivery offer from User, contacted through the glogc.com platform. Any
      decision to offer or accept delivery services once such User that is
      matched through the Glogc.com platform is a decision made solely between
      the user and GoLoger without liability or responsibility to glogc.com and
      GoBuilders Netsoft Sdn. Bhd. Additionally, Glogc.com and GoBuilders
      Netsoft Sdn. Bhd. shall not be liable for the actions and transactions of
      the user and GoLoger and be treated as absolutely blameless at all times.
      Please read these terms and conditions carefully before signing up and
      using the services. If you do not agree to be bound by these terms and
      conditions, please do not use or access the GoLog platform or sign up for
      any of the services provided on the platform. You may elect to read these
      terms and conditions again at any time after signing up and/or using our
      services.<br />4.&nbsp;&nbsp;&nbsp;&nbsp;These terms and conditions, and
      its updates are subject to the discretion of the GoLog management team at
      any time, notice thereof shall be posted on the GoLog platform as and when
      needed. You are bound by all amended terms or updates which become
      effective upon their posting on the glogc.com.
    </p>
    <p>
      <strong>PURPOSE OF SCOPE AND OPERATIONS</strong><br />GoLog is an online
      delivery service application that opens to all markets. Anyone can
      register and log in through the apps choose to be driver or register as a
      customer to use the delivery or pick up services which will be our
      products. Driver and rider can earn income through delivery and pick up
      services in the meantime users enjoy cheaper fare and fast services. It is
      similar to UBER but GoLog is rather a pick-up delivery service.<br />&nbsp;<br />GoLog
      does not undertake these jobs nor does it employ individuals to complete
      jobs. Additionally, GoLog does not supervise, direct or control the jobs a
      GoLoger accepts to which the GoLoger and User acknowledges.
    </p>
    <p>
      <strong>USER EVALUATION</strong><br />Users of GoLog are subject to an
      extensive evaluation process when they register to be a GoLoger and during
      their use of the GoLog platform including but not limited to a
      verification of identity and a comprehensive criminal background check.
      Those seeking to register to be a GoLoger hereby consent to GoLog
      conducting these background checks as often as necessary in compliance
      with Malaysia&rsquo;s state and federal laws.
    </p>
    <p>
      <strong>CONTRACT BETWEEN GOLOGER AND USER</strong><br />By using GoLog
      website you acknowledge that a contract is formed when you agree on the
      terms of a job with another user. As a User, you acknowledge that you
      offer an agreed financial sum as payment to the GoLoger for a job that you
      have assigned which may include reimbursement for costs taken-on by the
      GoLoger. As a GoLoger, you acknowledge that you will comply strictly with
      the User&rsquo;s instructions in exchange for payment that you have agreed
      to, understanding that you will have to assume responsibility of your own
      costs and utilize your own method of transport in pursuit of completing
      the job.
    </p>
    <p>
      Payment for jobs can be done through the payment service provider (as
      defined below). Both parties agree to notify GoLog of any disputes arising
      from the job at least (30) days before initiating any proceedings or
      action against the other party, utilizing GoLog as an intermediary and
      mediator to resolve the aforementioned disputes.
    </p>
    <p>
      <strong>ACCEPTABLE USE POLICY STATEMENT</strong><br />Users of GoLog may
      not in any way utilize the online platform for any activity that:<br />Violates
      any state and federal law or government regulation in Malaysia.<br />Violates
      any rules or regulations dictated by our payment service provider.<br />Violates
      any rule or regulation stating the acceptable code of conduct enforced by
      Golog on its Gologers.<br />Violates any rule or regulation by Visa,
      Mastercard or any Credit Card Network that is utilized by Golog on its
      platform for payment use.<br />Is fraudulent, deceptive, unfair or
      predatory<br />Causes or threatens reputational damage to Golog or any
      Credit Card Network.<br />Results in or creates a significant risk of
      chargebacks, penalties, damages or other harm or liability.<br />Involves
      any of the business categories which are age-restricted such as alcoholic
      beverage (exceptions apply if age is verified).
    </p>
    <p>
      <strong>TERMINATION AND SUSPENSION</strong><br />GoLog reserves the right
      to terminate or suspend your right to use the online platform in the event
      we believe that you have breached this Agreement by providing you with a
      written or email notice of such breach/termination/suspension that will be
      effective immediately upon issuance. If your right to use GoLog is
      terminated or suspended, this agreement will remain enforceable against
      you. You may terminate this agreement at any time by ceasing all use of
      the GoLog platform. All sections which by their nature should survive the
      expiration or termination of this agreement shall continue in full force
      and effect subsequent to and notwithstanding the expiration or termination
      of this agreement.
    </p>
    <p>
      <strong>ELIGIBILITY AND REPRESENTATIONS</strong
      ><br />1.&nbsp;&nbsp;&nbsp;&nbsp;Our services at GoLog and through this
      platform are available only to, and may only be used by individuals of 18
      years of age and above. By signing up and/or participating as a User or
      GoLoger, you represent and warrant that you meet this requirement. You
      also represent and warrant that you have the right, authority, and full
      capacity to be a User or GoLoger.<br />2.&nbsp;&nbsp;&nbsp;&nbsp;Your
      information is any information you provide, publish or display ("publish")
      to the glogc.com platform or send to other Users in the registration or in
      any public message area (including, but not limited to the feedback
      section) or through any email feature ("your information"). Your
      information will be stored on computers and on our business server.
      However, any personal information provided by you shall be collected,
      stored, and retained by GoBuilders Netsoft Sdn. Bhd. in accordance with
      the provisions of the Personal Data Protection Act 2010, a copy of the act
      is available for view at the library records of the Bar Council of
      Malaysia or online. By your sign up and/or usage of the services, you
      consent to us using your information to create a user account that will
      allow you to participate in the services.<br />3.&nbsp;&nbsp;&nbsp;&nbsp;By
      signing up in the glogc.com platform and / or using the services, you
      agree that we may obtain personal information including and without
      limitation to your identification, contact details or points, driving
      record, references, and/or credit information. You hereby authorize GoLog
      and GoBuilders Netsoft Sdn Bhd to perform necessary background checks for
      its purposes, and further agrees to provide any necessary authorization to
      facilitate our access to necessary information for the purposes of the
      business of GoLog.<br />4.&nbsp;&nbsp;&nbsp;&nbsp;You are solely
      responsible for your information and your interactions with other people
      in the public, and we act only as a passive conduit for the online posting
      of your information. When you use the GoLog platform, you agree to provide
      accurate, current and complete information as prompted by our registration
      form and to maintain and timely update your information to keep it
      accurate, current and complete at all times during the term of the
      agreement. You also agree that GoBuilders Netsoft Sdn Bhd, participants in
      the GoLog platform and the public may rely on your information as
      accurate, current and complete. You acknowledge that if your information
      is untrue, inaccurate, not current or incomplete in any respect, we have
      the right to terminate this your use of the services without notice to
      you.<br />5.&nbsp;&nbsp;&nbsp;&nbsp;You are allowed to one (1) GoLog
      account on the GoLog platform or authority over one (1) GoLog account
      thereon. You are also responsible for maintaining the confidentiality of
      any password provided by you or GoLog for accessing the services.
      Additionally, you are solely and fully responsible for all activities that
      occur under your password or account.<br />6.&nbsp;&nbsp;&nbsp;&nbsp;GoLog
      represents that it has no control over the use of its GoLoger or user's
      account at all times and expressly disclaims any or all liability derived
      therefrom. Should you suspect that any unauthorized party may be using
      your password or account or you suspect any other breach of security, you
      must contact the GoLog administrators immediately. GoLog and GoBuilders
      Netsoft Sdn Bhd reserves the right to take any legal action against you
      for misrepresentation for any loss or damage suffered as a result of any
      misuse or abuse of your account.<br />7.&nbsp;&nbsp;&nbsp;&nbsp;GoLog
      tries its best to protect the privacy of data exchange in the GoLog
      platform from misrepresentation, third party abuse or internal hacking but
      shall not be liable or claims no admission to liability in the event of
      any damage or loss occurs to the user or GoLoger. All data exchange
      outside the GoLog platform between the user and GoLoger is strictly at the
      risk of the user and GoLoger.<br />8.&nbsp;&nbsp;&nbsp;&nbsp;By using the
      glogc.com platform and the services, you as the User, represents, warrants
      and agrees that:
    </p>
    <p>
      a. The user had made all necessary checks before posting tasks on the
      glogc.com<br />
      platform;<br />
      b. The user will obey all local laws related to the matters set forth
      herein, and will be <br />
      solely responsible for any violations of such local laws including being
      involved in any <br />
      criminal, drug or unlawful activities;<br />
      c. The user is solely responsible and shall indemnify all claimants
      including Gobuilders <br />
      Netsoft Sdn Bhd And its representatives against any misconduct, abuse,
      misrepresent <br />
      -tation or wrongful allegation regarding or involving glogc.com, the
      glogc.com <br />
      platform in the posting of the user up to the delivery of tasks mentioned
      in the user <br />
      for the user at all times;<br />
      d. The user shall not treat, name, or hold glogc.com or Gobuilders Netsoft
      Sdn Bhd <br />
      responsible for any misconduct, abuse, misrepresentation or wrongful
      allegation <br />
      regarding or involving glogc.com, the glogc.com platform by the gologer;
      and<br />
      e. The user makes and will make all necessary declarations on the
      glogc.com platform <br />
      or if asked from time to time by Gobuilders Netsoft Sdn Bhd about
      restricted activities.
    </p>
    <p>
      By using the glogc.com platform and the services, the Gologer, however,
      represents, warrants and agrees that:<br />a.&nbsp;&nbsp;&nbsp;The Gologer
      makes and will make all necessary declarations on the glogc.com platform
      or if asked from time to time by Gobuilders Netsoft Sdn Bhd about
      restricted activities;<br />b.&nbsp;&nbsp;&nbsp;&nbsp;The Gologer
      possesses a valid driver&rsquo;s license and is authorized to operate a
      third party insured motor vehicle (&ldquo;vehicle&rdquo;) and has all
      appropriate licenses, approvals, and authority to provide delivery to
      third parties in all jurisdictions in which such Gologer uses the
      services;<br />c.&nbsp;&nbsp;&nbsp;&nbsp;The Gologer owns or has the legal
      right to operate the vehicle when making delivery tasks as per the user
      and that such vehicle is in good operating condition including meeting the
      Malaysian vehicle safety standards in accordance with the Malaysian
      law;<br />d.&nbsp;&nbsp;&nbsp;The Gologer&rsquo;s vehicle has a valid
      policy copy of third-party liability insurance for the operation of such
      Gologer&rsquo;s vehicle to cover any anticipated losses related to such
      Gologer&rsquo;s provision of deliveries to users;<br />e.&nbsp;&nbsp;&nbsp;&nbsp;The
      Gologer will be responsible for any and all damages, loss, and harm which
      results from the operation of the vehicle of the Gologer including, but
      not limited to, personal injuries, death and property damages, and
      absolutely indemnifies Gobuilders Netsoft Sdn Bhd If damages, loss, and
      harm occur to it and glogc.com;<br />f.&nbsp;&nbsp;&nbsp;&nbsp;The Gologer
      will be solely responsible for its own health and safety during the
      delivery of tasks accepted;<br />g.&nbsp;&nbsp;&nbsp;The Gologer will obey
      all local laws related to the matters set forth herein, and will be solely
      responsible for any violations of such local laws;<br />h.&nbsp;&nbsp;&nbsp;The
      Gologer is solely responsible and shall indemnify all claimants including
      Gobuilders Netsoft Sdn Bhd And its representatives against any misconduct,
      abuse, misrepresentation or wrongful allegation regarding or involving
      glogc.com, the glogc.com platform in the discharge of its delivery or the
      services for the user at all times;<br />i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The
      Gologer shall not treat, name or hold glogc.com or Gobuilders Netsoft Sdn
      Bhd responsible for any misconduct, abuse, misrepresentation or wrongful
      allegation regarding or involving glogc.com, the glogc.com platform by the
      user or user.
    </p>
    <p>
      <strong>INDEPENDENT CONTRACTORS</strong
      ><br />1.&nbsp;&nbsp;&nbsp;&nbsp;All GoLogers are independent contractors
      of GoBuilders Netsoft Sdn Bhd only.<br />2.&nbsp;&nbsp;&nbsp;&nbsp;GoLoger's
      relationship through the glogc.com platform and with GoBuilders Netsoft
      Sdn Bhd shall be treated as neither a partner nor an employee of
      GoBuilders Netsoft Sdn Bhd. GoLoger shall be responsible for all taxes in
      respect of fees and monies and his own social security contributions and
      the GoLoger undertakes to compensate GoBuilders Netsoft Sdn Bhd in full on
      demand for any liability it and its representatives suffer if the
      Malaysian Inland Revenue Board or other relevant government department
      treats the GoLoger in this case as an employee for the purposes of
      taxation and/or social security.<br />3.&nbsp;&nbsp;&nbsp;&nbsp;By being a
      GoLoger, GoBuilders Netsoft Sdn Bhd is entitled to submit any necessary
      information and document collection to the Malaysian Inland Revenue Board
      or any other government department without needing a court order for the
      purposes of resolving any issue faced with respect to the GoLoger.
    </p>
    <p>
      <strong>GOLOGER TIPS AND FEES</strong><br />1.&nbsp;&nbsp;&nbsp;&nbsp;As a
      User, you agree to assume full liability for the payment of the delivery
      fee to the assigned GoLoger. glogc.com and GoBuilders Netsoft Sdn Bhd
      shall not be held liable for any event of default on behalf of the User or
      the GoLoger. Events of default include but are not limited to non-payment
      delivery fee to GoLoger and non-delivery of goods posted on the User to
      the User. glogc.com receives a matching fee (&ldquo;GoLog fee&rdquo;) for
      each successful post that matches a User to a GoLoger. A post is deemed
      successful once a GoLoger accepts a delivery request from User. In the
      event of non-payment by the User for whatever reason, GoBuilders Netsoft
      Sdn Bhd shall be entitled to claim back the matching fee from the User, a
      fine to you by GoBuilders Netsoft Sdn Bhd may automatically be imposed.<br />2.&nbsp;
      &nbsp;A&nbsp;matching fee will be deducted from the User&rsquo;s prepaid
      credits on the glogc.com platform (Log Points, or LP) after a User and
      GoLoger are successfully matched and completion of the request. Credits
      can be purchased on glogc.com using authorized credit cards, internet
      banking, and through any other payment means offered at the discretion of
      glogc.com. All matching fee charges, prepaid credits, and tips are
      non-refundable. The glogc.com platform and GoBuilders Netsoft Sdn Bhd
      operates a no refund policy which shall apply at all times regardless
      of:<br />a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A User&rsquo;s decision to
      terminate usage of glogc.com;<br />b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our
      decision to terminate a User&rsquo;s usage;<br />c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Disruption
      caused to our services either planned, accidental or intentional; or<br />d.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any
      other reason whatsoever.<br />3.&nbsp;&nbsp;&nbsp;&nbsp;glogc.com, at its
      sole discretion, may make available promotional offers with different
      features at any time and tips may be impacted if you are a preferred
      partner of the glogc.com platform and / or have a glogc.com preferred
      partner account with GoBuilders Netsoft Sdn Bhd. These promotional offers,
      unless made to you, shall have no bearing whatsoever on your offer or
      contract. glogc.com may change its matching fee as we deem necessary for
      our business. We encourage you to check the glogc.com platform and its
      contents periodically if you are interested in keeping abreast of the rate
      of our matching fee.<br />4.&nbsp;&nbsp;&nbsp;&nbsp;In the event that a
      User cancels a delivery request on the glogc.com platform after a GoLoger
      has arrived in the pick-up location for more than five (5) minutes. The
      User agrees to pay a &ldquo;cancellation fee&rdquo; equivalent to RM5.00
      to the GoLoger.<br />5.&nbsp;&nbsp;&nbsp;&nbsp;GoLoger is not responsible
      and will not provide any manpower to load and unload any items to and from
      their vehicles, any help needed from the GoLoger will be on individual
      deals or at good faith of the personnel. GoLog is not responsible for any
      damage done by GoLoger or User during any loading and unloading
      activities.
    </p>
    <p>
      <strong>RESTRICTED ACTIVITIES</strong><br />1.&nbsp;&nbsp;&nbsp;&nbsp;You
      agree that you will use the services in a manner consistent with any and
      all applicable laws and regulations. We reserve the right but are not
      obligated to investigate, inform the other users in the glogc.com
      regarding you and terminate your participation in the glogc.com platform
      if you have misused the glogc.com platform or the services, or behaved in
      a way which could be regarded as inappropriate or whose conduct is
      unlawful or illegal.<br />2.&nbsp;&nbsp;&nbsp;&nbsp;With respect to your
      participation on the glogc.com platform or through the services, you agree
      that you will not:<br />a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Impersonate any
      person or entity;<br />b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Stalk" or
      otherwise harass any person;<br />c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Express
      or imply that any statements you make are endorsed by us, without our
      <br />
      specific prior written consent;<br />d.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Use
      any robot, spider, site search/retrieval application, or other manual or
      automatic <br />
      device or process to retrieve, index, "data mine", or in any way reproduce
      or <br />
      circumvent the navigational structure or presentation of the services or
      its contents;<br />e.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Publish, distribute or
      reproduce in any way any copyrighted material, trademarks, or <br />
      other proprietary information without obtaining the prior consent of the
      owner of <br />
      such proprietary rights;<br />f.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Remove any
      copyright, trademark or other proprietary rights notices contained in the
      <br />
      service;<br />g.&nbsp;&nbsp;&nbsp;&nbsp; Interfere with or disrupt the
      services or the glogc.com platform or the servers or <br />
      networks connected to the services or the glogc.com platform;<br />h.&nbsp;&nbsp;&nbsp;&nbsp;Post,
      email or otherwise transmit any material that contains software viruses or
      any other computer code, files or programs designed to interrupt, destroy
      or limit the functionality of any computer software or hardware or
      telecommunications equipment;<br />i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Forge
      headers or otherwise manipulate identifiers in order to disguise the
      origin of any information transmitted through the service;<br />j.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Frame"
      or "mirror" any part of the service, without our prior written
      authorization or use meta tags or code or other devices containing any
      reference to us or the services or the glogc.com platform in order to
      direct any person to any other website for any purpose; or<br />k.&nbsp;&nbsp;&nbsp;&nbsp;Modify,
      adapt, sublicense, translate, sell, reverse engineer, decipher, decompile
      or otherwise disassemble any portion of the services or any software used
      on or for the services or cause others to do so.<br />3.&nbsp;&nbsp;&nbsp;&nbsp;Your
      further agree that your information and your interactions with GoBuilders
      Netsoft Sdn Bhd, on or through the glogc.com platform shall not:<br />a.&nbsp;&nbsp;&nbsp;&nbsp;Be
      false, inaccurate or misleading (directly or by omission or failure to
      update information);<br />b.&nbsp;&nbsp;&nbsp;&nbsp;Infringe any third
      party's rights, including but not limited to intellectual property rights,
      copyright, patent, trademark, trade secret or other proprietary rights or
      rights of publicity or privacy;<br />c.&nbsp;&nbsp;&nbsp;&nbsp;Violate any
      law, statute, ordinance or regulation or do anything that becomes a
      restricted activity as per these terms of service;<br />d.&nbsp;&nbsp;&nbsp;&nbsp;Be
      anything expressly or impliedly defamatory, trade libelous, abusive,
      obscene, profane, offensive, sexually-oriented, threatening, harassing,
      racially offensive or illegal material;<br />e.&nbsp;&nbsp;&nbsp;&nbsp;Contain
      any offensive anatomical or sexual references, or offensive sexually
      suggestive or connotative language;<br />f.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contain
      any viruses, trojan horses, worms, time bombs, cancelbots, easter eggs or
      other computer programming routines that may damage, detrimentally
      interfere with, surreptitiously intercept or expropriate any system, data
      or personal information;<br />g.&nbsp;&nbsp;&nbsp;&nbsp;Create liability
      for us or cause us to become subject to regulation as a delivery carrier
      or provider of courier service; or<br />h.&nbsp;&nbsp;&nbsp;&nbsp;Link
      directly or indirectly to any other website.<br />i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allow
      any transfer, use, or sell your glogc.com account and/or id to any other
      party.<br />j.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cause or allow to cause any
      infringement of policies, terms of service, terms, and conditions, and
      written agreements of the glogc.com and GoBuilders Netsoft Sdn Bhd
    </p>
    <p>
      We reserve the right, but we have no obligation, to reject or take action
      against any User or GoLoger that does not comply with these prohibitions.
    </p>
    <p>
      <strong>BILLING AND PAYMENT</strong
      ><br />1.&nbsp;&nbsp;&nbsp;&nbsp;Partners, clients, customers, Users or
      GoLogers of the Glogc.com platform may be expected to pay in advance for
      the services vide the glogc.com platform. In the event that neither
      applies, advance future payments shall be completed by the Users vide the
      designated method as prescribed in the glogc.com or GoBuilders Netsoft Sdn
      Bhd invoices.<br />2.&nbsp;&nbsp;&nbsp;&nbsp;All other invoices issued
      through the glogc.com platform or by GoBuilders Netsoft Sdn Bhd must be
      paid within fourteen (14) days of the date of the invoice unless otherwise
      agreed in writing by the GoBuilders Netsoft Sdn Bhd. In the event of late
      payment, a late payment interest shall be automatically levied on the
      amount outstanding before and after judgment at the rate of 8% of the
      total invoice sum and shall be in force from the due date until the date
      of payment. In addition, invoices unpaid for more than thirty (30) days
      after the invoice date will incur an automatic penalty surcharge of 10% of
      the outstanding amount (including the compounding interest).<br />3.&nbsp;&nbsp;&nbsp;&nbsp;If
      any amount of an invoice is disputed then the recipient shall inform
      glogc.com or GoBuilders Netsoft Sdn Bhd in writing of the grounds for such
      dispute within seven (7) days of delivery of the goods, failing which
      acceptance is deemed by the issuer.<br />4.&nbsp;&nbsp;&nbsp;&nbsp;glogc.com
      and / or GoBuilders Netsoft Sdn Bhd reserves the right to increase a
      quoted fee in the event that its GoLoger, User, partner, customer, or
      client requests a variation to the work agreed without any waiver to these
      terms and conditions.
    </p>
    <p>
      Users of the glogc.com platform acknowledge that glogc.com is not a party
      to any contracts for the jobs completed. Payment for the jobs is made
      directly from the User to the GoLoger and not by glogc.com
    </p>
    <p>
      By using glogc.com you will be required to provide your bank account
      details and credit/debit card to glogc.com and to the Payment Service
      Provider utilized by glogc.com.
    </p>
    <p>
      By agreeing to use glogc.com and not utilizing cash-on-hand payment, you
      agree to make online payments through glogc.com(s) payment service
      provider Molpay and thereby agree to their terms of
      service.&nbsp;(https://www.molpay.com/v3/terms-of-services)
    </p>
    <p>
      <strong>1. Payment Terms</strong><br />1.1 This constitutes a legal
      agreement between you and GoBuilders Netsoft Sdn Bhd, governing your
      access to and use of our Services as a paying customer.
    </p>
    <p>
      <strong>2. Changes of Terms</strong><br />2.1 We may amend the Payment
      Terms from time to time in our sole discretion without notice or liability
      to you. It is your responsibility to review these Payment Terms
      periodically. By continuing to use the Services following such amendments
      to the Payment Terms, you agree to be bound by such amendments. If you do
      not agree to the Payment Terms, now or at any time, please do not use the
      Site, Applications, Subscription Plans, or any of the Paid Services.
    </p>
    <p>
      <strong>3. Minimum Requirements</strong><br />3.1 To obtain or subscribe
      to Paid Services on or through glogc.com, you must provide certain
      registration information (more particularly set out in the Website Terms
      of Use) and obtain a glogc.com Limited account. You acknowledge that you
      are responsible for maintaining the security of and restricting access to,
      your account and password, and you agree to accept responsibility for all
      purchases and other activities that occur under your account. GoBuilders
      Netsoft Sdn Bhd sells its services only to those users who can legally
      make purchases with a credit card or other form of payment accepted by
      GoBuilders Netsoft Sdn Bhd.
    </p>
    <p>
      <strong>4. GoLog Commission Terms</strong><br />4.1 Payment:<br />1.&nbsp;&nbsp;&nbsp;&nbsp;By
      acting as a verified GoLoger, you authorize GoBuilders Netsoft Sdn. Bhd to
      charge the applicable fees, referred to as &ldquo;GoLog Fee&rdquo; to your
      designated billing payment method.<br />2.&nbsp;&nbsp;&nbsp;&nbsp;When a
      Gologer finishes a job, Users will be charged immediately based on
      distance sent<br />3.&nbsp;&nbsp;&nbsp;&nbsp;You agree to abide by the
      terms and conditions of our payment collections partner. Our payments
      collections partner is MolPay
      (https://www.molpay.com/v3/terms-of-services)<br />4.&nbsp;&nbsp;&nbsp;&nbsp;If
      your account has a due amount, you must settle the balance owed within
      seven (7) days through our payment&rsquo;s collections partner, failing
      which, glogc.com at its absolute discretion has the right to suspend your
      account until such time as payment is made.<br />5.&nbsp;&nbsp;&nbsp;&nbsp;Account
      balances can be set off by incentives or admin tips<br />6.&nbsp;&nbsp;&nbsp;&nbsp;glogc.com
      has full discretion in investigating and deeming fraudulent transactions,
      by which glogc.com has the ability to suspend your account.<br />7.&nbsp;&nbsp;&nbsp;&nbsp;As
      a GoLoger, you agree to pay GoLog a commission for matching you with a
      job. This commission will be 25% of the quantum of the ti li (e.g., Total
      tip of job is RM10, GoLog Fee is RM2.50)<br />8.&nbsp;&nbsp;&nbsp;&nbsp;In
      the event GST or any other ancillary charges are required to be added to
      the commission, GoLog has the right at its absolute discretion to revise
      the rate of commission to accommodate this.<br />4.2 Incentives:<br />From
      time to time, we may offer incentives for our GoLogers. glogc.com has full
      rights to determine the incentives as and when and may change them at any
      point in time. glogc.com can deem any sort of misinformation provided,
      abuse, or misuse of the platform; if so, glogc.com has full rights to
      suspend and take actions against the GoLoger or User. If the GoLoger has
      received any complaints, strikes, or reports that go against the standard
      policy as stipulated in the verification session, Glogc.com reserves the
      right to not provide the incentive. Upon any dispute on the incentive
      between Glogc.com and GoLoger, GoLog has the final decision on whether the
      GoLoger qualifies for the incentive.<br />4.3 Fair usage:<br />1.&nbsp;&nbsp;&nbsp;&nbsp;glogc.com
      has the right to withhold payment due to any GoLoger for any suspected
      fraudulent or phantom jobs. The investigation will take place and
      glogc.com has the final decision on whether the payment should be withheld
      permanently or not. If glogc.com or its officers conclude from an
      investigation that cheating has occurred, GoLog has the right to refuse
      payment to GoLoger.<br />2.&nbsp;&nbsp;&nbsp;&nbsp;You agree that
      glogc.com, in its sole discretion, with or without prior notice, may
      freeze or terminate your account for any reason, including, without
      limitation, if glogc.com believes that you have violated these Payment
      Terms or the Website Terms of Use. glogc.com may also in its sole
      discretion, and at any time, modify or discontinue providing any service,
      or any part thereof, with or without notice. Additionally, you agree that
      glogc.com shall not be liable to you or any third-party for any
      interference with, or termination of, your access to the account.<br />3.&nbsp;&nbsp;&nbsp;&nbsp;A
      refund will be granted upon the discretion of the company. Refunds will be
      determined based on the investigation, information submitted by users, and
      after the company has concluded that the charge for payment should be
      cancelled and the funds must be refunded to the user. Situations, where
      this will be allowed, is when the payment charge for GoLog commission
      should not take place because the job was in fact not completed.
      Completion definition is when the user paid for a service done by a
      GoLoger in the platform and community of GoLog.
    </p>
    <p>
      <strong>5. Price Adjustments</strong><br />5.1&nbsp;We may increase the
      commission payable at any point in time. If you continue to use glogc.com,
      you shall be deemed to have accepted the new cost for that renewal term
      and any subsequent renewal terms.<br />6. Accessing Our Services<br />6.1
      We make every effort to ensure that our Services are always available, but
      we cannot guarantee that Services will not be uninterrupted. We will not
      be liable to you if for any reason our Services are unavailable at any
      time or for any period.<br />6.2 You are responsible for making all
      arrangements necessary for you to have access to our Services. You are
      also responsible for ensuring that all persons who access our Services
      through your internet connection are aware of these Payment Terms and
      other applicable terms and conditions and that they comply with them.
    </p>
    <p>
      <strong>INSURANCE</strong><br />1.&nbsp;&nbsp;&nbsp;&nbsp;All GoLogers are
      responsible to obtain their insurance or protection against risk and shall
      not hold glogc.com and / or GoBuilders Netsoft Sdn Bhd liable or
      responsible for damage or loss suffered. However, glogc.com and / or
      GoBuilders Netsoft Sdn Bhd reserves the right to recover compensation or
      claim indemnity against the User and/or GoLoger for direct or indirect
      damage or loss suffered.<br />2. glogc.com, we do not procure insurance
      for, nor are we responsible for instances of personal loss or injury, and
      property loss or damage experienced during your participation with
      glogc.com or its platform. We do&nbsp;not actively procure insurance for
      items delivered as part of any delivery service offered by GoLoger to the
      User over its User. Users need to opt-in insurance for the items at a
      minimal charge payable to glogc.com for protection coverage on loss and
      damaged items.<br />3.&nbsp;&nbsp;&nbsp; To protect the smooth operations
      of the Glogc.com platform, GoBuilders Netsoft Sdn Bhd is insured against
      risk and loss directly caused by glogc.com employees and GoBuilders
      Netsoft Sdn Bhd.
    </p>
    <p>
      <strong>SOCIAL MEDIA AND NETWORKING SITES</strong
      ><br />1.&nbsp;&nbsp;&nbsp;&nbsp;As part of the functionality of the
      glogc.com platform, you may be able to log in through online accounts you
      may have with third-party service providers (each such account, a
      &ldquo;third party account&rdquo;) by either providing your third-party
      account login information through the glogc.com platform; or allowing
      glogc.com to access your third-party account, as is permitted under the
      applicable terms and conditions that govern your use of each third-party
      account.<br />2.&nbsp;&nbsp;&nbsp;&nbsp;You represent that the glogc.com
      platform is entitled to disclose your information in the glogc.com
      platform, social media, and its networking sites, without breach by you of
      any of the terms and conditions that govern your use of the applicable
      third-party account and without obligating glogc.com to pay any fees or
      making glogc.com subject to any usage limitations imposed by such
      third-party service providers. By granting glogc.com access to any
      third-party accounts, you understand that glogc.com may access, make
      available and store (if applicable) any content that you have provided to
      and stored in your third party account (the &ldquo;social networking
      site/SNS content&rdquo;) so that it is available on and through the
      glogc.com platform via your account, including without limitation any
      friend, contacts or following/followed lists, and Glogc.com may submit and
      receive additional information to your third party account as indicated
      herein.
    </p>
    <p>
      <strong>GOLOG E-MAIL AND TEXT COMMUNICATIONS</strong><br />E-mail
      communications and text messages sent from or through the glogc.com
      platform or GoBuilders Netsoft Sdn Bhd is designed to make your glogc.com
      experience more efficient. By becoming a User, you specifically agree to
      accept and consent to receive e-mail communications and text messages
      initiated from us or through us, which include, without limitation:
      message notification e-mails, e-mails or text messages informing you about
      potential available GoLogers or Users and e-mails informing you of
      promotions we run and emails informing you of new and existing features we
      provide. Standard text messaging charges applied by your cell phone
      carrier will apply to text messages we send. If you change your mobile
      phone service provider, the notification service may be deactivated for
      your phone number and you may need to re-enroll in the notification
      service. glogc.com reserves the right to cancel the notification service
      at any time. If you do not wish to receive any of our e-mail
      communications or text messages, please do not use the services or sign up
      in the glogc.com platform.
    </p>
    <p>
      <strong>INFORMATION CONTROL</strong><br />Location data provided by the
      glogc.com platform is for basic location purposes only and is not intended
      to be relied upon in situations where precise location information is
      needed or where erroneous, inaccurate or incomplete location data may lead
      to death, personal injury, property or environmental damage. Neither
      glogc.com, nor any of its content providers, guarantees the availability,
      accuracy, completeness, reliability, or timeliness of location data
      displayed by the services. Any of your information, including
      geo-locational data, you upload, provide, or post on the glogc.com
      platform may be accessible to certain spaces in the glogc.com platform. We
      cannot verify or guarantee the accuracy of the information a User provides
      us on the glogc.com platform, and we do not control the information
      provided by other Users that is made available through our system.
      Therefore, glogc.com cannot and does not confirm each User's purported
      identity. You may find other User's information to be offensive, harmful,
      inaccurate, or deceptive. Please use caution and common sense when using
      the glogc.com platform. Please note that there are also risks of dealing
      with underage persons or people acting under false pretense. By using the
      glogc.com platform, you agree to accept such risks and glogc.com is not
      responsible for the acts or omissions of others on the glogc.com platform.
      In order to help you evaluate with whom you are dealing, glogc.com can
      link to a User's facebook.com profile if they supply us with their
      facebook.com account information.
    </p>
    <p>
      <strong>PROPRIETARY RIGHTS</strong
      ><br />1.&nbsp;&nbsp;&nbsp;&nbsp;GoBuilders Netsoft Sdn Bhd owns and
      retains ownership of the glogc.com platform, and all intellectual property
      therein. Contingent upon your compliance with the terms and conditions of
      this agreement, glogc.com hereby grants to you a limited,
      non-transferable, non-exclusive, non-assignable, revocable license to use
      the Glogc.com platform on any desktop or mobile device that you own or
      control and as permitted by these terms and conditions of service. This
      license does not allow you to use the glogc.com platform on any mobile
      device that you do not own or control, and you may not distribute or make
      the glogc.com platform available over a network where it could be used by
      multiple devices at the same time. You may not rent, lease, lend, sell,
      redistribute, or sublicense the Glogc.com platform. You may not copy
      (except as expressly permitted by this license and the usage rules),
      decompile, reverse engineer, disassemble, attempt to derive the source
      code of, modify, or create derivative works of the Glogc.com platform, any
      updates, or any part thereof (except as and only to the extent any
      foregoing restriction is prohibited by applicable law). Any attempt to do
      so is a violation of the rights of Glogc.com and its licensors. If you
      breach this restriction, you may be subject to prosecution and damages.
      The terms of the license will govern any upgrades provided by glogc.com
      that replace and / or supplement the glogc.com platform unless such
      upgrade is accompanied by a separate license in which case the terms of
      that license will govern.<br />2.&nbsp;&nbsp;&nbsp;&nbsp;You warrant and
      represent to us that your information is posted by you and that you are
      the sole author of your information. To enable the glogc.com platform to
      use your information without violating any rights you might have in such
      information, you automatically grant, and you represent and warrant that
      you have the right to grant, to us and GoBuilders Netsoft Sdn Bhd, a
      non-exclusive, worldwide, perpetual, irrevocable, royalty-free,
      sub-licensable (through multiple tiers) right to exercise the copyright,
      publicity, and database rights you have in your information and your
      content, and to use, copy, perform, display and distribute such
      information and content and to prepare derivative works of, or incorporate
      into other works, such information, and content, in any media now known or
      not currently known, with respect to your information. glogc.com will only
      use your information and content in accordance with our privacy policy.
      You may remove your content or your information from the glogc.com
      platform at any time. If you choose to remove your content or your
      information, the license granted above will automatically expire, however
      you acknowledge that glogc.com may retain archived copies of your content.
      glogc.com does not assert any ownership over your content; rather, as
      between us and you, subject to the rights granted to us in these terms of
      service, you retain full ownership of all of your content and any
      intellectual property rights or other proprietary rights associated with
      your content. In addition, other Users may post copyrighted information on
      the glogc.com platform, which has copyright protection whether or not it
      is identified as copyrighted. Except for that information which is in the
      public domain or for which you have been given permission, you will not
      copy, modify, publish, transmit, distribute, perform, display, or sell any
      such proprietary information on the Glogc.com platform.<br />3.&nbsp;&nbsp;&nbsp;&nbsp;GoBuilders
      Netsoft Sdn Bhd and the glogc.com platform respects the intellectual
      property of others and expects Users to do the same. If you believe, in
      good faith, that any materials on the Services infringe upon your
      copyrights, please send the following information to glogc.com vide the
      glogc.com platform:<br />a.&nbsp;&nbsp;&nbsp;&nbsp;A description of the
      copyrighted work that you claim has been infringed, including specific
      location on the services where the material you claim is infringed is
      located. Include enough information to allow glogc.com to locate the
      material, and explain why you think an infringement has taken place;<br />b.&nbsp;&nbsp;&nbsp;&nbsp;A
      description of the location where the original or an authorized copy of
      the copyrighted work exists &ndash; for example, the URL (internet
      address) where it is posted or the name of the book in which it has been
      published;<br />c.&nbsp;&nbsp;&nbsp;&nbsp;Your address, telephone number,
      and e-mail address;<br />d.&nbsp;&nbsp; A statement by you that you have a
      good faith belief that the disputed use is not authorized by the copyright
      owner, its agent, or the law;<br />e.&nbsp;&nbsp;&nbsp;&nbsp;A statement
      by you, made under penalty of perjury, that the information in your notice
      is accurate, and that you are the copyright owner or authorized to act on
      the copyright owner's behalf; and<br />f.&nbsp;&nbsp;&nbsp;&nbsp;An
      electronic or physical signature of the owner of the copyright or the
      person authorized to act on behalf of the owner of the copyright interest.
    </p>
    <p>
      <strong>NOTICES &amp; COMPLAINTS</strong
      ><br />1.&nbsp;&nbsp;&nbsp;&nbsp;Except as explicitly stated otherwise,
      any notices to Glogc.com shall be given by certified mail, postage prepaid
      and return receipt requested to:
    </p>
    <p>
      GOBUILDERS NETSOFT SDN BHD<br />No.26, Jalan Industri Mas 14,<br />Taman
      Mas,<br />47100 Puchong,<br />Selangor Darul Ehsan.
    </p>
    <p>
      2.&nbsp;&nbsp;&nbsp;&nbsp;Additionally, any notices to you shall be
      provided to you through the glogc.com platform or given to you via the
      email address you provide to glogc.com during the registration process. In
      such case, notice shall be deemed given three (3) days after the date that
      the email was sent. Notice shall be deemed given 24 hours after email is
      sent, unless the sending party is notified that the email address is
      invalid. Alternatively, we may give you notice by certified mail, postage
      prepaid and return receipt requested, to the address provided to glogc.com
      during the registration process. In such case, notice shall be deemed
      given three (3) days after the date of mailing.<br />3.&nbsp;&nbsp;&nbsp;&nbsp;To
      resolve a complaint regarding the service, you should first contact our
      customer service department by email at&nbsp;admin@glogc.com. If our
      customer service department is not able to resolve your complaint within
      sixty (60) days from the date of the filing, you may write to us
      officially on the matter at the address above, failing a response within
      fourteen (14) days thereof, file a complaint with the Tribunal for
      Consumer Complaints of Malaysia as stipulated in the Consumer Protection
      Act 1999.
    </p>
    <p>
      <strong>BREACH &amp; INDEMNITY</strong
      ><br />1.&nbsp;&nbsp;&nbsp;&nbsp;Without limiting other remedies, we may
      terminate your participation, remove your information, warn our community
      of your actions, issue a warning, and refuse to provide our services to
      you if:<br />a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You breach this
      agreement or the documents it incorporates by reference;<br />b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We
      are unable to verify or authenticate any information you provide to us;<br />c.&nbsp;&nbsp;&nbsp;&nbsp;We
      believe that your actions may cause financial loss or legal liability for
      you, our Users or us, or subject glogc.com or you or any other User to
      regulation by any state or local government or regulatory agency; or<br />d.&nbsp;&nbsp;&nbsp;&nbsp;If
      we suspect that you have engaged in fraudulent activity in connection with
      the glogc.com platform or the services.<br />2.&nbsp;&nbsp;&nbsp;&nbsp;You
      will defend, indemnify, and hold us and our officers, directors,
      employees, agents and any third parties harmless for any losses, costs,
      liabilities, and expenses (including reasonable attorneys' fees) relating
      to or arising out of your use of the service, including:<br />a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your
      breach of this agreement or the documents it incorporates by reference;<br />b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your
      violation of any law or the rights of a third party, including, without
      limitation, <br />
      GoLogers, Users, Users, other motorists, and pedestrians, as a result of
      your own interaction with such third party,<br />c.&nbsp;&nbsp;&nbsp;&nbsp;Any
      allegation that any materials that you submit to us or transmit to the
      services or to us infringe or otherwise violate the copyright, trademark,
      trade secret or other intellectual property or other rights of any third
      party;<br />d.&nbsp;&nbsp;&nbsp;&nbsp;Your ownership, use or operation of
      a motor vehicle or passenger vehicle, including your provision of
      deliveries to Users; and / or<br />e.&nbsp;&nbsp;&nbsp;&nbsp;Any other
      activities in connection with the services. This indemnity shall be
      applicable without regard to the negligence of any party, including any
      indemnified person.
    </p>
    <p>
      <strong>ONLINE CONTENT DISCLAIMER</strong
      ><br />1.&nbsp;&nbsp;&nbsp;&nbsp;Opinions, advice, statements, offers, or
      other information or content made available through the services, but not
      directly by us, are those of their respective authors, and should not
      necessarily be relied upon. Such authors are solely responsible for such
      content. We do not guarantee the accuracy, completeness, or usefulness of
      any information on the services and neither do we adopt nor endorse nor
      are we responsible for the accuracy or reliability of any opinion, advice,
      or statement made by parties other than us. Under no circumstances will we
      be responsible for any loss or damage resulting from anyone's reliance on
      information or other content posted on the services, or transmitted to
      Users. We reserve the right, but we have no obligation, to monitor the
      materials posted in the public areas of the services. Notwithstanding this
      right, you remain solely responsible for the content of the photos,
      profiles (including your name, image, and likeness), messages, notes,
      text, information, music, video, advertisements, listings, and other
      content (the "content") that you post in the public areas of the services
      and in your private e-mail messages. We shall have the right to remove any
      such material that in our sole opinion violates, or is alleged to violate,
      the law or this agreement or which might be offensive, illegal, or that
      might violate the rights, harm, or threaten the safety of Users or others.
      E-mails sent between you and other Users that are not readily accessible
      to the general public will be treated by us as private to the extent
      required by applicable law.<br />2.&nbsp;&nbsp;&nbsp;&nbsp;The glogc.com
      platform contains (or you may be sent through the glogc.com platform or
      the services) links to other web sites ("third-party sites") as well as
      articles, photographs, text, graphics, pictures, designs, music, sound,
      video, information, applications, software and other content or items
      belonging to or originating from third parties (the "third-party
      applications, software or content"). Such third party sites and
      third-party applications, software or content are not investigated,
      monitored or checked for accuracy, appropriateness, or completeness by us,
      and we are not responsible for any third-party sites accessed through the
      glogc.com platform or any third-party applications, software or content
      posted on, available through or installed from the glogc.com platform,
      including the content, accuracy, offensiveness, opinions, reliability,
      privacy practices or other policies of or contained in the third-party
      sites or the third-party applications, software or content. Inclusion of,
      linking to or permitting the use or installation of any third-party site
      or any third-party applications, software or content does not imply
      approval or endorsement thereof by us. If you decide to leave the
      glogc.com platform and access the third-party sites or to use or install
      any third-party applications, software or content, you do so at your own
      risk and you should be aware that our terms and policies no longer govern.
      You should review the applicable terms and policies, including privacy and
      data gathering practices, of any web site to which you navigate from the
      glogc.com platform or relating to any applications you use or install from
      the glogc.com platform.<br />3.&nbsp;&nbsp;&nbsp;&nbsp;In the event that
      you have a dispute with one or more Users, you agree to release glogc.com
      and GoBuilders Netsoft Sdn Bhd (and our officers, directors, agents,
      subsidiaries, joint ventures, and employees) from claims, demands and
      damages (actual and consequential) of every kind and nature, known and
      unknown, suspected and unsuspected, disclosed and undisclosed, arising out
      of or in any way connected to such disputes with other Users or to your
      use of the glogc.com platform or the services. In other words, you are
      solely responsible for your interactions with other Users. We reserve the
      right, but have no obligation, to monitor disputes between you and other
      Users. Please carefully select the type of information that you post on
      the glogc.com platform or through the services or release to others. We
      disclaim all liability, regardless of the form of action, for the acts or
      omissions of other Users or Users (including unauthorized users, or
      "hackers"). glogc.com only offers a venue that enables GoLogers and Users
      to match with each other. glogc.com does not offer delivery services and
      glogc.com is not a delivery company. We are not involved in the actual
      delivery provided by GoLogers to Users. As a result, we have no control
      over the quality or reliability of the delivery that occurs as a result of
      the service; nor do we have any control over the truth or accuracy of the
      Users' information listed on the glogc.com platform. We cannot ensure that
      a GoLoger or User is who he or she claims to be or that a GoLoger or User
      will actually complete an arranged service. We reserve the right to change
      any and all content, software and other items used or contained in the
      glogc.com platform and the services at any time without notice. Reference
      to any products, services, processes or other information, by trade name,
      trademark, manufacturer, supplier or otherwise does not constitute or
      imply endorsement, sponsorship or recommendation thereof, or any
      affiliation therewith, by glogc.com or the glogc.com platform.<br />4.&nbsp;&nbsp;&nbsp;&nbsp;The
      glogc.com platform and the services may be temporarily unavailable from
      time to time for maintenance or other reasons. glogc.com assumes no
      responsibility for any error, omission, interruption, deletion, defect,
      delay in operation or transmission, communications line failure, theft or
      destruction or unauthorized access to, or alteration of, User
      communications. glogc.com is not responsible for any technical malfunction
      or other problems of any telephone network or service, computer systems,
      servers or providers, computer or mobile phone equipment, software,
      failure of email or players on account of technical problems or traffic
      congestion on the internet, on the glogc.com platform, on any web site or
      any combination thereof, including injury or damage to User's or to any
      other person's computer, mobile phone, or other hardware or software,
      related to or resulting from using or downloading materials in connection
      with the web and/or in connection with the services.
    </p>
    <p>
      <strong>LIMITATION OF LIABILITY</strong><br />1.&nbsp;&nbsp;&nbsp;&nbsp;We
      at glogc.com and GoBuilders Netsoft Sdn Bhd, our subsidiaries, officers,
      directors, employees, and our suppliers provide the glogc.com platform and
      the services on an "as is" basis and without any warranty or condition,
      express, implied or statutory. We do not guarantee and do not promise any
      specific results from use of the glogc.com platform and/or the services.
      We, our subsidiaries, officers, directors, employees, and our suppliers
      specifically disclaim any implied warranties of title, merchantability,
      fitness for a particular purpose and non-infringement. We do not warrant
      that your use of the services will be accurate, complete, reliable,
      current, secure, uninterrupted, always available, or error-free, or will
      meet your requirements, that any defects in the services will be
      corrected, or that the services are free of viruses or other harmful
      components. We disclaim liability for, and no warranty is made with
      respect to, connectivity and availability. We cannot guarantee that each
      User is at least the required minimum age, nor do we accept responsibility
      or liability for any content, communication or other use or access of the
      glogc.com platform or the services by persons under the age of eighteen
      (18) in violation of this agreement. We are not responsible or liable in
      any manner for any content posted on the glogc.com platform or in
      connection with the service, whether posted or caused by Users of the
      glogc.com platform, by glogc.com, by third parties or by any of the
      equipment or programming associated with or utilized in the glogc.com
      platform or the services. Although we provide rules for User conduct and
      postings, we do not control and are not responsible for what User&rsquo;s
      post, transmit or share on the glogc.com platform and are not responsible
      for any offensive, inappropriate, obscene, unlawful or otherwise
      objectionable content you may encounter on the glogc.com platform or in
      connection with any content. glogc.com is not responsible for the conduct,
      whether online or offline, of any User of the glogc.com platform or
      services. It also is possible for others to obtain personal information
      about you due to your use of the glogc.com platform or the services, and
      that the recipient may use such information to harass or injure you. We
      are not responsible for the use of any personal information that you
      disclose on the glogc.com platform or through the services.<br />2.&nbsp;&nbsp;&nbsp;&nbsp;In
      no event will we, our subsidiaries, officers, directors, employees or our
      suppliers, be liable to you for any incidental, consequential, or direct
      damages (including, but not limited to, damages for deletion, corruption,
      loss of data, loss of programs, failure to store any information or other
      content maintained or transmitted by our services, service interruptions,
      or for the cost of procurement of substitute services) arising out of or
      in connection with glogc.com, our services or this agreement (however
      arising, including negligence) even if we or our agents or representatives
      know or have been advised of the possibility of such damages. We do not
      screen the tasks posted on the glogc.com using the services in any way. As
      a result, we will not be liable for any damages, direct, indirect,
      incidental and/or consequential, arising out of the use of glogc.com or
      the services, including, without limitation, to damages arising out of
      communicating and/or meeting with other Users of glogc.com or the services
      or introduced to you via glogc.com or the services. Such damages include,
      without limitation, physical damages, bodily injury, death, and or
      emotional distress and discomfort. Notwithstanding anything to the
      contrary contained herein, our liability, and the liability of our
      subsidiaries, officers, directors, employees, and suppliers, to you or any
      third parties in any circumstance is limited to up to 10% or the value of
      the good in question or RM 100.00, whichever is lower.
    </p>
    <p>
      <strong>CONFIDENTIALITY</strong><br />You agree not to use any technical,
      financial, strategic, and other proprietary and confidential information
      relating to glogc.com&rsquo;s business, operations and properties
      (&ldquo;confidential information&rdquo;) disclosed to you by glogc.com for
      your own use or for any purpose other than as contemplated herein. You
      shall not disclose or permit disclosure of any confidential information to
      third parties. You agree to take all reasonable measures to protect the
      secrecy of and avoid disclosure or use of confidential information of
      glogc.com in order to prevent it from falling into the public domain.
      Notwithstanding the above, you shall not have liability to glogc.com with
      regard to any confidential information which you can prove: (a) was in the
      public domain at the time it was disclosed by glogc.com or has entered the
      public domain through no fault of yours; (b) was known to you, without
      restriction, at the time of disclosure, as demonstrated by files in
      existence at the time of disclosure; (c) is disclosed with the prior
      written approval of glogc.com; (d) becomes known to you, without
      restriction, from a source other than glogc.com without breach of this
      agreement by you and otherwise not in violation of glogc.com&rsquo;s
      rights; or (e) is disclosed pursuant to the order or requirement of a
      court, administrative agency, or other governmental bodies; provided,
      however, that you shall provide prompt notice of such court order or
      requirement to glogc.com to enable glogc.com to seek a protective order or
      otherwise prevent or restrict such disclosure.
    </p>
    <p>
      <strong>TERMINATION</strong><br />Either you or we may terminate your
      participation in the glogc.com platform by removing your information at
      any time, for any or no reason, without explanation, effective upon
      sending written or email notice to the other party. Upon such termination,
      we will remove all of your information from our servers, though we may
      retain an archived copy of records we have about you as required by law or
      for legitimate business purposes. We maintain sole discretion to bar your
      use of the services in the future, for any or no reason. Even after your
      participation in the glogc.com platform is terminated, you remain bound to
      the terms of confidentiality in this service term and condition.
    </p>
  </div>
</template>
<script>
export default {};
</script>
